<template>
  <b-row>
    <b-col sm="12">
      <table-server-side-v-1-widget
        url="api/admin/userTransactionData?filter=creditPurchase"
        :columns="columns"
        :actions="actions"
        @clickViewDetail="row => $refs.refModalUserWallpaperHistory.show(row)"
        @clickViewUser="onClickViewUser"
      >
        <template #transaction_data_as_status_text="{row}">
          <b-badge :variant="gFormatPaymentStatusToVariant(row.transaction_data_as_status_text)">
            {{ row.transaction_data_as_status_text }}
          </b-badge>
        </template>
      </table-server-side-v-1-widget>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'IndexPurchaseCreditTable',
  props: {},
  data() {
    return {
      columns: [
        {
          label: 'อีเมล / เบอร์โทรศัพท์',
          field: 'user_as_email',
        },
        {
          label: 'ชื่อ สกุล',
          field: 'user_as_name',
        },
        {
          label: 'เลขอ้างอิง',
          field: 'transaction_data_as_outside_id',
        },
        {
          label: 'เครดิต เดิม',
          field: 'transaction_data_as_credit_balance',
          // type: 'currency',
          tdClass: 'text-right',
          formatFn: value => {
            if (!value) return '-'
            return this.gFormatNumberToCurrency(value)
          },
        },
        {
          label: 'เครดิต เติม',
          field: 'transaction_data_as_credit_amount',
          type: 'currency',
          tdClass: 'text-right',
        },
        {
          label: 'เครดิต รวม',
          field: 'transaction_data_as_credit_total',
          tdClass: 'text-right',
          formatFn: value => {
            if (!value) return '-'
            return this.gFormatNumberToCurrency(value)
          },
        },
        {
          label: 'ช่องทางการเติม',
          field: 'transaction_data_as_payment_chanel',
          thClass: 'text-center',
          tdClass: 'text-center',
        },

        {
          label: 'สถานะ',
          field: 'transaction_data_as_status_text',
          tdClass: 'text-center',
          type: 'custom',
        },
        {
          label: 'วันที่ (ปี-เดือน-วัน)',
          field: 'created_at',
          formatFn: value => this.$date(value).format('YYYY-MM-DD HH:mm:ss'),
          tdClass: 'text-center',
          thClass: 'text-center',
        },
      ],
      actions: [{ keyEmit: 'clickViewUser', label: 'ไปหน้าข้อมูลลูกค้า', iconName: 'UserIcon' }],
    }
  },
  methods: {
    onClickViewUser(row) {
      // console.log('onClickViewUser', row)
      this.$router.push({ name: 'userManagement-user-info', params: { userId: row.transaction_data_as_user_id } })
    },
  },
}
</script>

<style lang="scss"></style>
